<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">Welcome, Explorer!</h1>
    </div>
    <div class="bg-set">
      <h2 class="h3">Follow the story to mint your Big Bag!</h2>

    <p></p>
   <div v-if="step==0">
    <p>You are walking in a Bazaar in Mars' outpost in an alternate Metaverse, the year is 2151.</p>
    <p>Something catches your eye! </p> <p>It's sparkling from inside an exquisitely made Red Denim Bag.</p>
     <button @click="step++"  type="button" class="btn btn-success btn-space">Take a closer look...</button>
          <button @click="skipToMint"  type="button" class="btn btn-secondary btn-space">Skip to Mint</button>

   </div>
    <div v-if="step==1">
    <p>You go to take a closer look, when suddenly a Young Man appears!</p>
     <p>Young Man: "Hello Adventurer! Welcome to our store!</p><p>Young Man: "We sell the finest bags containing some of the most priceless trinket's from Old Earth!"</p>
          <p>Young Man: "You can even put your own crypto tokens and NFTs into these bags to earn interest!" <br>(Actually true. Read more in section below for details)</p>
        <p>Young Man: "These bags seem to be selling fast!"</p>
<button @click="step++"  type="button" class="btn btn-success btn-space">How many do you have left?</button>
     <button @click="step--"  type="button" class="btn btn-secondary btn-space">Go Back</button>
</div>

 <div v-if="!isUserConnected && step==2">
             <p>Young Man: "Hmm..I actually don't know how many Bags we have left until we connect to the Polygon Network.</p>

          <button   type="button" class="btn btn-success btn-space" href="#"  v-if="!isUserConnected" @click="connectWeb3Modal" >Connect Wallet to Continue</button>
               <button @click="step--"  type="button" class="btn btn-secondary  btn-space">Go Back</button>

 </div>
               <p v-if="isUserConnected && !getChainName=='Polygon'">Please connect to Polygon Network by selecting it in your wallet</p>

 <div v-if="isUserConnected && step==2 && getChainName=='Polygon'" >
         <p v-if="getRemainingBalance>=7000 && answer==0">Young Man: "Looks like you're an early customer! We still have {{getRemainingBalance}} Bags!"</p>
         <p v-if="getRemainingBalance<7000 && getRemainingBalance!=0 && answer==0">Young Man: "We still have {{getRemainingBalance}} Bags! Don't wait too long until we run out!</p>
         <p v-if="getRemainingBalance==0 && answer==0">Young Man: "Oops! Sorry we are all sold out now! You may still buy our Bags on a secondary NFT Marketplace</p>
         <p v-if="getRemainingBalance==0 && answer==0">Just make sure you use the Genuine Checker before you buy!</p>
<div v-if="getRemainingBalance>0">
         <p v-if="answer==0"> Young Man: "Would you like to buy one?"</p>

            <button @click="setAnswer(1)" v-if="answer==0" type="button" class="btn btn-success btn-space">Yes I want one!</button>
      <button @click="setAnswer(2)" v-if="answer==0" type="button" class="btn btn-warning btn-space">No, i'm just browsing</button>

    <p v-if="answer==1">Young Man replies: "Excellent choice! I'm excited as to what trinkets you will receive in your awesome Bag!"</p>
        <p v-if="answer==2">Young Man replies: "No worries! I'm sure you will love to know that each Bag comes with 5 of the over 600 random fun trinkets we have!"</p>
            <button  v-if="answer==1" type="button" @click="gotoMint" class="btn btn-success btn-space">Buy a bag (Proceed to Mint Page)</button>
            <button v-if="answer==2" type="button" @click="gotoMint" class="btn btn-success btn-space">You sold me! I'll buy a bag (Proceed to Mint Page)</button>
           <button @click="reset"  v-if="answer==1 || answer==2" type="button" class="btn btn-secondary  btn-space">Restart Story</button>

 </div>
<p></p>
</div>
<div class="small-text">
<p class="small-text">Minting Smart Contract: <a href="https://polygonscan.com/address/0xce9e49959D84E75423D18369062086B2B7138569" target="_blank">PolygonScan</a> | <a href="https://github.com/scorpnode/BigBagsNFT/blob/main/BigMint.sol" target="_blank">Github</a></p>
<p class="small-text">ERC-721 Charged Particle(Proton) Smart Contract: <a href="https://polygonscan.com/address/0xe2a9b15E283456894246499Fb912CCe717f83319" target="_blank">PolygonScan</a> | <a href="https://github.com/Charged-Particles/" target="_blank">Github</a></p> 
<p class="small-text">Powered by<a href="https://polygon.technology" target="_blank">Polygon Network</a> | <a href="https://aave.com" target="_blank">Aave</a> | <a href="https://charged.fi" target="_blank">Charged.Fi</a></p> 

</div>
<p></p><p></p><p></p>
</div>
    <!-- <p v-if="isUserConnected">
      <strong>Your current chain:</strong> {{getChainName}}
    </p> -->

    <!-- <router-link v-if="isUserConnected" to="/set-value">
      <button type="button" class="btn btn-outline-primary btn-lg">Go set a new value!</button>
    </router-link> -->
     <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">Sample Previews</h1>
        
    </div>
       <vs-card-group>

  <vs-card v-for="n in 20" :key="n">   
    <template #img>
      <img :src="'/samples/sample'+n+'.png'"/>
    </template>
  </vs-card>
  
  </vs-card-group>
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">Our Big Bags Utility NFTs</h1>
       
    </div>
        <div class="bg-set">
          <h2 class="h3">No gas wars on Polygon! No presales! Fair launch from day 1</h2>

        <p>Here's 10 reasons why you should get them!</p>

    <p>1) 10,000 unique text-art NFT bags made from <u>5 different textures and 15 different colors.</u></p>
        <p>2) <u>Actual NFT Bag!</u> You can add your NFTs and crypto tokens into this bag!</p>
        <p>3) <u>Earn interest</u> by depositing your tokens! Powered by: <a href="https://charged.fi" target="_blank">Charged.Fi</a></p>
    <p>4) Collect 5 of <u>over 600 random</u> legendary trinkets for your next Quest in every Bag!</p>
    <p>5) An <u>expansive metaverse based story.</u> Reveal upon 10,000NFT sales milestone. We can't wait!</p>
      <p>6) You gain <u>exclusive</u> entry to our <u>Members Only Club</u> if you own at least one Big Bag.</p>
      <p>7) Bragging rights! Need I say more?</p>
      <p>8) Novelty! Be one of the first people on Polygon to own an NFT bag!</p>
      <p>9) <u>Easter Eggs?</u> You'll find them in our upcoming story and also the Earthly trinkets!</p>
      <p>10) Potential resale value appreciation (...not financial advice of course).</p>
        </div>



 <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">Other information</h1>
    
    </div>
         <div class="bg-set">
   <p>Each Big Bag will cost 100 MATIC to mint excluding gas fees. </p>
       <p>We will start selling from Day 1 of launch.</p>

       <p>We will collect a 3% Royalty on any future resales. </p>
       <p>Up to 50 out of the 10,000 NFTs will be pre-minted for giveaways and as rewards to our partners</p>
<p> </p>


<p> </p>
         </div>
 <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
  
    </div>

    
<footer class="page-footer font-small blue pt-4">

  <!-- Footer Links -->
  <div class="container-fluid text-center">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 mt-md-0">

        <!-- Content -->
        <h5 class="text-uppercase">BigBags.Club (2021)</h5>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none pb-3">


    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->

  <!-- Copyright -->

</footer>

    <!-- <router-link v-if="isUserConnected" to="/set-value">
      <button type="button" class="btn btn-outline-primary btn-lg">Go set a new value!</button>
    </router-link> -->
  </div>
 
  
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ref } from '@vue/composition-api'

export default {
  name: "Home",
  computed: {
    ...mapGetters("accounts", ["getChainName", "isUserConnected","getActiveBalanceEth","getActiveAccount", "getProviderEthers"]),
        ...mapGetters("contracts", ["getRemainingBalance","getBigMintAbi", "getBigMintAddress"]),
 
  },
  created(){

   
  },
  mounted(){

  },
    methods: {
    ...mapActions("accounts", ["connectWeb3Modal", "disconnectWeb3Modal"]),
    onClickButton () {
            this.isBarOpen=!this.isBarOpen

      this.$emit('childToParent', this.isBarOpen)
      console.log("emitting")
    },
    gotoMint (){
     this.$router.replace({ path: `/mint` })
    }
  },
   data:() => ({
      active: 'start',
      isBarOpen:false,
    }),
  setup(){
    const answer=ref(0)
    const step=ref(0)
    const setAnswer=(val)=>{
      if(val==1 || val==2){
      answer.value=val
      }

    }
      const skipToMint=()=>{
      
      answer.value=1
      step.value=2

    }
     const reset=()=>{
      
      answer.value=0
      step.value=0

    }
    return{
      step,
      setAnswer,
      answer,
      skipToMint,
      reset
    }
  }
}
</script>
<style scoped>
.btn-space {
  margin: 5px;
}
.btn-warning, .btn-warning:active, .btn-warning:visited {
    background-color: #FF8F00 !important;
}
.btn-warning:hover{
      background-color: #FF6F00 !important;

}
.bg-set{
  padding:20px;
  background-color: #282828;
    border-radius: 25px;
}
.app-link img{

        max-width: 300px;
  height: auto;
}
.small-text p{
 font-size:12px;
}
.small-text a{
 font-size:12px;
}
.mars-banner img{

        max-width: 100%;
  height: auto;
}
</style>
