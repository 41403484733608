import Vue from 'vue'
import App from './App.vue'
import store from "./store/index.js";
import router from "./router.js";
import Toasted from 'vue-toasted';
import Vuesax from 'vuesax';

import VueCompositionAPI from '@vue/composition-api';

import '@/assets/css/vuesax.css' //Vuesax styles
Vue.use(Vuesax, {
  // options here
})

Vue.use(Toasted);
Vue.use(VueCompositionAPI);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
