<template>
  <div>

  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h1">How to use your BigBag</h1>
    </div>
<p>Step 1: Visit the <a href="https://app.charged.fi/go/manage" target="_blank">Charge.fi</a> website and manage your NFTs. </p>
    <p class="mars-banner"><img src="guide1.png"></p>
<p>Step 2: Select your BigBag NFT. It should display all the information about your Bag. </p>
    <p class="mars-banner"><img src="guide2.png"></p>
<p>Step 3: To start depositing tokens into your Bag, select the "Energize" button and select your desired token, amount and hit Approve. </p>
    <p class="guide3"><img src="guide3.png"></p>

<p>Step 4: After Approval, hit Energize! Please choose from the Aave dropdown to generate interest. (Please notethe %APY interest on Charged.fi's website can sometimes appear incorrectly. Check Aave's website for more accurate rates.</p>
<p>List of interest bearing tokens are: USDT, WMATIC, DAI, USDC, WETH and WBTC as per the Aave dropdown selection. </p>
    <p class="guide3"><img src="guide4.png"></p>

<p>Withdrawal: Click the Release button to withdraw your assets from the Bag. </p>
    <p class="guide3"><img src="guide5.png"></p>

<p>Lastly, we hope you enjoy the Bag itself! You can see other Bags and what they hold by visiting the (upcoming) Bag List section</p>


<p> </p>
 

    
<footer class="page-footer font-small blue pt-4">

  <!-- Footer Links -->
  <div class="container-fluid text-center">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 mt-md-0">

        <!-- Content -->
        <h5 class="text-uppercase">BigBags.Club (2021)</h5>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none pb-3">


    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->

  <!-- Copyright -->

</footer>

    <!-- <router-link v-if="isUserConnected" to="/set-value">
      <button type="button" class="btn btn-outline-primary btn-lg">Go set a new value!</button>
    </router-link> -->
  </div>
 
  
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Guide",
  computed: {
    ...mapGetters("accounts", ["getChainName", "isUserConnected"]),
  },
}
</script>
<style scoped>
.app-link img{

        max-width: 300px;
  height: auto;
}
.mars-banner img{

        max-width: 100%;
  height: auto;
}
.guide3 img{

        max-width: 50%;
  height: auto;
}
p {
    margin-bottom: 30px;
}

</style>
