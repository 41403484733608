<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h1">Mint Your Big Bag!</h1>
    </div>
 <div class="bg-set">
<h3 class="h3">We have {{getRemainingBalance}} Bags left </h3> 
<p>Your support is extremely appreciated!</p> 
<p> Minting even 1 NFT really helps!</p>
  <button  @click="mintNFT" v-if="isUserConnected && getChainName=='Polygon'" type="button" class="btn btn-success btn-space">Buy a bag (Mint NFT for 100 MATIC)</button>
<p v-if="!isUserConnected || getChainName!='Polygon'" >Please connect your wallet to Polygon Network to proceed</p>
<div class="small-text">
<p class="small-text">Minting Smart Contract: <a href="https://polygonscan.com/address/0xce9e49959D84E75423D18369062086B2B7138569" target="_blank">PolygonScan</a> | <a href="https://github.com/scorpnode/BigBagsNFT/blob/main/BigMint.sol" target="_blank">Github</a></p>
<p class="small-text">ERC-721 Charged Particle(Proton) Smart Contract: <a href="https://polygonscan.com/address/0xe2a9b15E283456894246499Fb912CCe717f83319" target="_blank">PolygonScan</a> | <a href="https://github.com/Charged-Particles/" target="_blank">Github</a></p> 
<p class="small-text">Powered by<a href="https://polygon.technology" target="_blank">Polygon Network</a> | <a href="https://aave.com" target="_blank">Aave</a> | <a href="https://charged.fi" target="_blank">Charged.Fi</a></p> 
</div>
<div v-if=" purchasedArray.length>0" class="displayNFT">
       <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">Your Minted Big Bag</h1>
    </div>
    <h3 class="h3">Thank you for your purchase! It means a lot! </h3> 
<div  v-for="(purchases,index) in purchasedArray" :key="index">
<div class="card" style="width: 600px;">
  <img :src="'https://ipfs.io/ipfs/QmQk44dVspNBVgCXXPppB6DiPWkmH8MKyGcPsAkW7Dxz6G/'+purchases.bagNo+'.png'" class="card-img-top">
  <div class="card-body">
    <h5 class="card-title">Big Bag#{{purchases.bagNo}}</h5>
        <h5 class="card-title">Token ID:{{purchases.tokenId}}</h5>

    <p class="card-text">If you wish to add your NFT to MetaMask, the contract address is: 0xe2a9b15E283456894246499Fb912CCe717f83319</p>
        <p class="card-text">You can deposit your crypto assets to Big Bag at <a href="https://charged.fi" target="_blank">Charged.Fi</a>.</p>
  </div>
</div>
</div>
  </div>
 </div>
<div v-if="purchasedArray.length==0">
  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">Sample Previews</h1>
        
    </div>
       <vs-card-group>

  <vs-card v-for="n in 20" :key="n">   
    <template #img>
      <img :src="'/samples/sample'+n+'.png'"/>
    </template>
  </vs-card>
  
  </vs-card-group>
 </div>   
<footer class="page-footer font-small blue pt-4">

  <!-- Footer Links -->
  <div class="container-fluid text-center">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 mt-md-0">

        <!-- Content -->
        <h5 class="text-uppercase">BigBags.Club (2021)</h5>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none pb-3">


    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->

  <!-- Copyright -->

</footer>

    <!-- <router-link v-if="isUserConnected" to="/set-value">
      <button type="button" class="btn btn-outline-primary btn-lg">Go set a new value!</button>
    </router-link> -->
  </div>
 
  
</template>

<script>
import { mapGetters } from "vuex";
import { ethers } from "ethers";
import Proton from "../contracts/Proton.json";
export default {
  name: "Mint",
  computed: {
    ...mapGetters("accounts", ["getChainName", "isUserConnected","getActiveBalanceEth","getActiveAccount", "getProviderEthers"]),
        ...mapGetters("contracts", ["getRemainingBalance","getBigMintAbi", "getBigMintAddress"]),
  },
  created() {
    this.$store.dispatch("contracts/storeBigMintAbi");

    this.$store.dispatch("contracts/fetchRemainingNFTs");
    this.$store.dispatch("contracts/storeBigMintAddress");

    if (!this.getProviderEthers) {
            //  document.location.href="/";
              if (history.length == 0) {
       document.location.href="/";
    } else {
        history.go(-1);
    }

    } else {
      // get the contract instance
      let signer = this.getProviderEthers.getSigner(); 
      this.contract = new ethers.Contract(this.getBigMintAddress, this.getBigMintAbi, signer);
      let component = this;
      this.contract2= new ethers.Contract("0xe2a9b15E283456894246499Fb912CCe717f83319",Proton.abi,signer);
      // set event listener
      this.contract.on("mintSuccessful", (tokenId, bagNumber) => {
        component.callMintSuccessful(tokenId, bagNumber);
        // show a toast
     
     
        component.$store.dispatch("contracts/fetchRemainingNFTs");

        // refresh the num value
        // component.$store.dispatch("contracts/fetchNum");
      });
    }
  },
  data() {
    return {
      contract: null,
      contract2: null,
      purchasedArray:[],
    }
  },
   methods: {
     async callMintSuccessful(tokenId, bagNumber){
   let getOwner = await this.contract2.ownerOf(tokenId);
        if(this.getActiveAccount.toLowerCase()==getOwner.toLowerCase())
        {
   this.$toasted.show('Congrats! You have successfully minted a Big Bag!' , {
          type: 'success',
          duration: 5000,
          theme: "bubble",
          position: "top-center"
        });
            this.purchasedArray.push({tokenId:tokenId,bagNo:bagNumber})

        }

     },
    async mintNFT() {
      let toPay = {
    // To convert Ether to Wei:
    value: ethers.utils.parseEther("100")     // ether in this case MUST be a string
};

      await this.contract.mint_nft(toPay);
    }
  },
}
</script>
<style scoped>
.displayNFT p{
  color:#000;
    font-size:14px;

}
.displayNFT h5{
  color:#000;
}
.displayNFT a{
  color:#000;
  font-size:14px;
}
.app-link img{

        max-width: 300px;
  height: auto;
}
.mars-banner img{

        max-width: 100%;
  height: auto;
}
.small-text p{
 font-size:12px;
}
.small-text a{
 font-size:12px;
}
.bg-set{
  padding:20px;
  background-color: #282828;
    border-radius: 25px;
}
</style>
