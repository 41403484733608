<template>
  <div>

    <Navbar />

    <div class="container-fluid">
      <div class="row">
    <div class="nav col-md-4 col-lg-3 d-md-block  sidebar collapse" id="sidebarMenu">

        <Sidebar />
    </div>
        <main class="col-md-8 ms-sm-auto col-lg-9 px-md-4 ">

          <keep-alive>
            <router-view />
          </keep-alive>

        </main>
      </div>
    </div>

  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Sidebar from './components/Sidebar.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    Sidebar
  }
}
</script>
