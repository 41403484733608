<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h1">Frequently Asked Questions</h1>
    </div>

<div class="accordion" id="accordionGame">
  <div class="accordion-item">
    <h6 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
       Only 10,000 NFTS? What about the rest of us who want to own one?
      </button>
    </h6>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionGame">
      <div class="accordion-body">
Only 10,000 unique NFTs will ever be minted on the Polygon Network. This is to provide exclusivity to the owners and the club. You may buy one on secondary NFT Marketplaces.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h6 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        What will the story metaverse be like?
      </button>
    </h6>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionGame">
      <div class="accordion-body">
        We promise you the storyline with be expansive with some humor involved! This will later link with an NFT Play-to-Earn Game.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h6 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
How do i deposit Crypto ERC20 Tokens and NFT ERC721 Tokens into my Big Bag?
      </button>
    </h6>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionGame">
      <div class="accordion-body">
Please check out <a href="https://charged.fi" target="_blank">Charged.Fi</a> where you can find all of the required information.
      </div>
    </div>
  </div>
    <div class="accordion-item">
    <h6 class="accordion-header" id="headingFour">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
How do i earn interest from my deposited Tokens?
      </button>
    </h6>
    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionGame">
      <div class="accordion-body">
You can earn interest by depositing tokens into your Big Bags. See <a href="https://charged.fi" target="_blank">Charged.Fi</a> where you can find all of the required information.

      </div>
    </div>
  </div>
    <div class="accordion-item">
    <h6 class="accordion-header" id="headingFive">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
How will resale royalties be used?
      </button>
    </h6>
    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionGame">
      <div class="accordion-body">
We will be collecting the 3% resale royalties and be using the funds to develop more blockchain dApps. This includes an NFT-based play-to-earn game!
    </div>
  </div></div>
     <div class="accordion-item">
    <h6 class="accordion-header" id="headingSeven">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
Who are these NFTs for?      </button>
    </h6>
    <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionGame">
      <div class="accordion-body">
Our target audience for the NFTs are those who have curious souls to explore the latest bleeding technology such as this NFT Bag and also those who enjoy a little bit of laughter from super random stuff
      </div>
    </div>
  </div>
     <div class="accordion-item">
    <h6 class="accordion-header" id="headingSix">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
How can i check if my purchase is genuine?
      </button>
    </h6>
    <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionGame">
      <div class="accordion-body">
We will be launching a genuine checker within 48 hours of completion of NFT sales.
      </div>
    </div>
  </div>
   <div class="accordion-item">
    <h6 class="accordion-header" id="headingEight">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
How do i add my Big Bag to Metamask?
      </button>
    </h6>
    <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionGame">
      <div class="accordion-body">
Visit <a href="https://charged.fi">Charged.Fi</a> and view your wallet. It should display your token ID and the contract address for the NFT.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h6 class="accordion-header" id="headingNine">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
Why is the NFT called a Proton? 
      </button>
    </h6>
    <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionGame">
      <div class="accordion-body">
The NFT is a Supercharged Particle and supported by <a href="https://charged.fi">Charged.Fi</a> for depositing crypto assets and earning interest from it!
      </div>
    </div>
  </div>
 <div class="accordion-item">
    <h6 class="accordion-header" id="headingTen">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
What is the interest rate you will earn?
      </button>
    </h6>
    <div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionGame">
      <div class="accordion-body">
You will be earning Aave Protocol interest rates. You can deposit your tokens using the <a href="https://charged.fi">Charged.Fi</a> website.
      </div>
    </div>
  </div>

</div>
    
<footer class="page-footer font-small blue pt-4">

  <!-- Footer Links -->
  <div class="container-fluid text-center">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 mt-md-0">

        <!-- Content -->
        <h5 class="text-uppercase">BigBags.Club (2021)</h5>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none pb-3">


    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->

  <!-- Copyright -->

</footer>

    <!-- <router-link v-if="isUserConnected" to="/set-value">
      <button type="button" class="btn btn-outline-primary btn-lg">Go set a new value!</button>
    </router-link> -->
  </div>
 
  
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FAQ",
  computed: {
    ...mapGetters("accounts", ["getChainName", "isUserConnected"]),
  },
}
</script>
<style scoped>
.app-link img{

        max-width: 300px;
  height: auto;
}
.mars-banner img{

        max-width: 100%;
  height: auto;
}
a {
  color:#000;
  font-size:14px;
}

</style>
