import { ethers } from "ethers";
import BigMint from "../../contracts/BigMint.json";
import addresses from "../../contracts/addresses.json";

const state = {
  remainingNFTs: 0,
  bigMintAbi: null,
  bigMintAddress: null
};

const getters = {
  getRemainingBalance(state) {
    return state.remainingNFTs;
  },
  getBigMintAbi(state) {
    return state.bigMintAbi;
  },
  getBigMintAddress(state) {
    return state.bigMintAddress;
  }
};

const actions = {
  async fetchRemainingNFTs({ commit, rootState }) {
    let provider = rootState.accounts.providerEthers;
    let chainIdDec = parseInt(rootState.accounts.chainId);
    let bigMintAddress = addresses.BigMint[chainIdDec];
    console.log(provider)
    let contract = new ethers.Contract(bigMintAddress, BigMint.abi, provider);

    let num = await contract.NFTcount();
    
    commit("setRemainingNFTs", 10000-num);
  },
  storeBigMintAbi({commit}) {
    commit("setBigMintAbi", BigMint.abi);
  },
  storeBigMintAddress({ commit, rootState }) {
    let chainIdDec = parseInt(rootState.accounts.chainId);
    let bigMintAddress = addresses.BigMint[chainIdDec];

    commit("setBigMintAddress", bigMintAddress);
  }
};

const mutations = {
  setRemainingNFTs(state, _num) {
    state.remainingNFTs = _num;
  },
  setBigMintAbi(state, abi) {
    state.bigMintAbi = abi;
  },
  setBigMintAddress(state, address) {
    state.bigMintAddress = address;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
