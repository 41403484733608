<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h1">We ♥ Crypto and Gaming!</h1>
    </div>
<div class="bg-set">

<p>We are a small team and are everyday people just like you with big dreams!</p> 
<p>You can help us make our dreams a reality! Even one NFT purchase counts!</p> 
<p>The money will be put to developing more Ethereum & Polygon based dApps for Big Bags members.</p> 
</div>
  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h1">Meet The Team</h1>

    </div>
  <vs-card-group>

  <vs-card>
    <template #title class="team-text">

      <h3>ENCORE</h3>
    </template>
    <template #img>
      <img src="/encore.png" alt="">
    </template>
    <template #text class="team-text">
      <p>
        <h7>
CTO</h7>      </p>
           <p>
        <h8>
        Avid Crypto and Blockchain enthusiast. Started getting into Tech and Programming since his early youth. Really needs the money to quit his day job and focus on blockchain projects!</h8>
      </p>
    </template>

  </vs-card>


  <vs-card>
    <template #title class="team-text">

      <h3>TIMESTREAM</h3>
    </template>
    <template #img>
      <img src="/timestream.png" alt="">
    </template>
    <template #text class="team-text">
      <p>
        <h7>
Head of Marketing</h7>      </p>
           <p>
        <h8>
        Digital marketing experience working with clients in various industries. When not working he can be seen playing Valorant or Dota2.</h8>
      </p>
    </template>

  </vs-card>

  </vs-card-group>

  

<p> </p>
 <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
  
    </div>

    
<footer class="page-footer font-small blue pt-4">

  <!-- Footer Links -->
  <div class="container-fluid text-center">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 mt-md-0">

        <!-- Content -->
        <h5 class="text-uppercase">BigBags.Club (2021)</h5>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none pb-3">


    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->

  <!-- Copyright -->

</footer>

    <!-- <router-link v-if="isUserConnected" to="/set-value">
      <button type="button" class="btn btn-outline-primary btn-lg">Go set a new value!</button>
    </router-link> -->
  </div>
 
  
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Team",
  computed: {
    ...mapGetters("accounts", ["getChainName", "isUserConnected"]),
  },
}
</script>
<style scoped>

.app-link img{

        max-width: 300px;
  height: auto;
}
.mars-banner img{

        max-width: 100%;
  height: auto;
}
.bg-set{
  padding:20px;
  background-color: #282828;
    border-radius: 25px;
}
</style>
