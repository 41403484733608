<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h1">Bag Explorer</h1>
    </div>
 <div class="bg-set">
<div class="input-group mb-3">
  <span class="input-group-text" id="basic-addon1">BigBag #</span>
  <input class="form-control" v-model="bagno" type="number" step="1" min="0" @input="filterInput" placeholder="Enter BigBag Number" aria-describedby="basic-addon1">
    <button @click="findBag" type="button" class="btn btn-success btn-space">Search</button>

</div>
  <p v-show="errormsg">{{errormsg}}</p>

 </div>
<div >
  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">Your Bags</h1>
     
    </div>
     <div class="bg-set">
<h3 class="h3">You have {{bagsCount}} Bags.</h3> 
<p v-if="bagsCount==0 && getRemainingBalance>0"><a href="/mint">Click Here</a> to mint one today!</p> 
 </div>  
       <vs-card-group v-if="bagsCount>0">

  <vs-card v-for="(pair,index) in pairs" :key="index" @click="goto(pair.bag)"> 

        <template #title class="team-text" >

    <h3>Big Bag No#{{pair.bag}}</h3>
        <h3>Token ID:{{pair.token}}</h3>

        </template>
    <template #img>
      <img :src="'https://ipfs.io/ipfs/QmQk44dVspNBVgCXXPppB6DiPWkmH8MKyGcPsAkW7Dxz6G/'+pair.bag.toString()+'.png'"/>
    </template>
    <template #text class="team-text dark-text" >
      <p>
       

</p>
          
    </template>
  </vs-card>

  </vs-card-group>
 </div>   
<footer class="page-footer font-small blue pt-4">

  <!-- Footer Links -->
  <div class="container-fluid text-center">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 mt-md-0">

        <!-- Content -->
        <h5 class="text-uppercase">BigBags.Club (2021)</h5>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none pb-3">


    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->

  <!-- Copyright -->

</footer>

    <!-- <router-link v-if="isUserConnected" to="/set-value">
      <button type="button" class="btn btn-outline-primary btn-lg">Go set a new value!</button>
    </router-link> -->
  </div>
 
  
</template>

<script>
import { mapGetters } from "vuex";
import { ethers } from "ethers";
import Proton from "../contracts/Proton.json";
export default {
  name: "BagExplorer",
  computed: {
    ...mapGetters("accounts", ["getChainName", "isUserConnected","getActiveBalanceEth","getActiveAccount", "getProviderEthers"]),
        ...mapGetters("contracts", ["getRemainingBalance","getBigMintAbi", "getBigMintAddress"]),
         pairs () {
      return this.bagIdsArray.map((bag, i) => {
        return {
          bag: bag,
          token: this.tokenIdsArray[i]
        }
      })
    }
  },
  created() {
    this.$store.dispatch("contracts/storeBigMintAbi");

    this.$store.dispatch("contracts/fetchRemainingNFTs");
    this.$store.dispatch("contracts/storeBigMintAddress");

    if (!this.getProviderEthers) {
             document.location.href="/";
       

    } else {
      // get the contract instance
      let signer = this.getProviderEthers.getSigner(); 
      this.contract = new ethers.Contract(this.getBigMintAddress, this.getBigMintAbi, signer);
      this.contract2= new ethers.Contract("0xe2a9b15E283456894246499Fb912CCe717f83319",Proton.abi,signer);
      this.getBagsCount();
      // set event listener
  
    }
  },
  
  data() {
    return {
      contract: null,
      contract2: null,
      bagIdsArray:[],
      tokenIdsArray:[],
      bagsCount: 0,
      bagsArray:[],
      bagno:null,
      errormsg:""
    }
  },
   methods: {
     goto(bag){
       this.$router.push({ path: `/viewbag/${bag}` })

     },
  async   findBag(){
if(isNaN(this.bagno)){
this.errormsg="Please enter a number"
}else if(this.bagno>0 && this.bagno<10001){
        let bagResult= await this.contract.BagIds(this.bagno);
      if(bagResult!=0){
       this.$router.push({ path: `/viewbag/${this.bagno}` })

      }else{
        this.errormsg="We could not locate that Big Bag #. It could be because it has not yet been minted."

      }

}else{
this.errormsg="Please enter a value between 1 to 10,000"

}

     },
      filterInput(e){
      e.target.value = e.target.value.replace(/[^0-9]+/g, '');
    },
     async getBagsCount(){
    this.bagsCount= await this.contract2.balanceOf(this.getActiveAccount.toLowerCase());
    if(this.bagsCount>0) {
  for(let i=0;i<this.bagsCount;i++){
     let tokenIds= await this.contract2.tokenOfOwnerByIndex(this.getActiveAccount.toLowerCase(),i);
      let bagIds= await this.contract.TokenIds(tokenIds);
      if(bagIds!=0){
       this.tokenIdsArray.push(tokenIds);
        this.bagIdsArray.push(bagIds);
      }
      
    }
    }
  
     },
  
 
  },
}
</script>
<style scoped>
.displayNFT p{
  color:#000;
    font-size:14px;

}

.displayNFT h5{
  color:#000;
}
.displayNFT a{
  color:#000;
  font-size:14px;
}
.app-link img{

        max-width: 300px;
  height: auto;
}
.mars-banner img{

        max-width: 100%;
  height: auto;
}
.small-text p{
 font-size:12px;
}
.small-text a{
 font-size:12px;
}
.bg-set{
  padding:20px;
  background-color: #282828;
    border-radius: 25px;
}
</style>
