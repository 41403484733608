<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h1">Bag Viewer</h1>
    </div>
 <div class="bg-set">
      <h1 class="h3">{{msg}}</h1>
 <p v-if="tokensArray.length==0 && showLoading==false && errormsg==false">No tokens found in the bag.</p>
 <p v-if="errormsg==true">The bag may be empty. Otherwise please try again</p>
  <vs-table v-if="tokensArray.length>0">
    <template #thead>
      <vs-tr>
        <vs-th>
          Token
        </vs-th>
        <vs-th>
          Amount
        </vs-th>
              <vs-th>
          Interest Bearing?
        </vs-th>
      </vs-tr>
    </template>
    <template #tbody>
      <vs-tr
      :key="i"
        v-for="(token, i) in tokensArray"
        :data="tokensArray"
      >
        <vs-td>
         {{token.symbol}}
        </vs-td>
        <vs-td>
{{token.amount  }}      </vs-td>
   <vs-td>
{{token.hasInterest  }}      </vs-td>
      </vs-tr>
    
    </template>
  </vs-table>
<div class="small-text"><p >Special thanks to: <a href="https://twitter.com/GbhoyDownUnder">@GbhoyDownUnder</a></p></div>
 </div>

<footer class="page-footer font-small blue pt-4">

  <!-- Footer Links -->
  <div class="container-fluid text-center">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 mt-md-0">

        <!-- Content -->
        <h5 class="text-uppercase">BigBags.Club (2021)</h5>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none pb-3">


    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->

  <!-- Copyright -->

</footer>

    <!-- <router-link v-if="isUserConnected" to="/set-value">
      <button type="button" class="btn btn-outline-primary btn-lg">Go set a new value!</button>
    </router-link> -->
  </div>
 
  
</template>

<script>
import { mapGetters } from "vuex";
import { ethers } from "ethers";
import ChargedParticles from "../contracts/ChargedParticles.json";
import axios from "axios";
export default {
  name: "ViewBag",
  computed: {
    ...mapGetters("accounts", ["getChainName", "isUserConnected","getActiveBalanceEth","getActiveAccount", "getProviderEthers"]),
        ...mapGetters("contracts", ["getRemainingBalance","getBigMintAbi", "getBigMintAddress"]),
  },
  created() {
    this.$store.dispatch("contracts/storeBigMintAbi");

    this.$store.dispatch("contracts/fetchRemainingNFTs");
    this.$store.dispatch("contracts/storeBigMintAddress");

    if (!this.getProviderEthers) {
             document.location.href="/";
       

    } else {
      // get the contract instance
      let signer = this.getProviderEthers.getSigner(); 
      this.contract = new ethers.Contract(this.getBigMintAddress, this.getBigMintAbi, signer);
      this.contract2= new ethers.Contract("0x0288280Df6221E7e9f23c1BB398c820ae0Aa6c10",ChargedParticles.abi,signer);
      
      // set event listener
    
    }
  },
  watch:{
    $route (){
 
      this.tokensArray=[]
        this.getBag(this.$route.params.id);
    }
} ,

  data() {
    return {
      contract: null,
      contract2: null,
      bagIdsArray:[],
      tokenId:null,
      bagsCount: 0,
      bagsArray:[],
      tokensArray:[],
      showLoading:false,
      bagno:null,
      msg:"",
      errormsg:false,
    }
  },
   methods: {

  async getBag(num){
if(isNaN(num)){
this.msg="Invalid Bag Request"

}else if(num>0 && num<10001){
       this.tokenId= await this.contract.BagIds(num);
 if(this.tokenId.toNumber()!=0){
   console.log(this.tokenId.toNumber())
this.bagno=num;
this.msg=`Big Bag #${this.bagno}:`
this.showLoading=true;
axios.post('https://api.thegraph.com/subgraphs/name/charged-particles/polygon-universe', {
    query: `
      query GenericSmartWallet($tokenId: BigInt!) {
        genericSmartWallets(where: {tokenId: $tokenId}) {
                        assetBalances{
                tokenId
                assetToken
                  name
                  symbol
                  decimals
                  principal
                
              }
        }
      }`,
      variables: {
        tokenId: this.tokenId.toNumber()
      }
  })
.then((response) => {

 // console.log(response.data.data.genericSmartWallets);
  if(response.data.data.genericSmartWallets.length>0){
   for(let i=0; i< response.data.data.genericSmartWallets[0].assetBalances.length;i++){
     if(response.data.data.genericSmartWallets[0].assetBalances[i].principal!="0"){
       const decimals = parseInt(response.data.data.genericSmartWallets[0].assetBalances[i].decimals, 10)
       let principal=parseInt(response.data.data.genericSmartWallets[0].assetBalances[i].principal, 10)
        this.tokensArray.push({"amount":(principal/10**decimals).toPrecision(9),"symbol":response.data.data.genericSmartWallets[0].assetBalances[i].symbol,"hasInterest":"No"})
     
     }
   }
//console.log(this.tokensArray)
  }
axios.post('https://api.thegraph.com/subgraphs/name/charged-particles/polygon-universe', {
    query: `
      query AaveSmartWallet($tokenId: BigInt!) {
        aaveSmartWallets(where: {tokenId: $tokenId}) {
                        assetBalances{
                tokenId
                assetToken
                  name
                  symbol
                  decimals
                  principal
                
              }
        }
      }`,
      variables: {
        tokenId: this.tokenId.toNumber()
      }
  })
.then((response) => {
  if(response.data.data.aaveSmartWallets.length>0){
   for(let i=0; i< response.data.data.aaveSmartWallets[0].assetBalances.length;i++){
     if(response.data.data.aaveSmartWallets[0].assetBalances[i].principal!="0"){
       const decimals = parseInt(response.data.data.aaveSmartWallets[0].assetBalances[i].decimals, 10)
       let principal=parseInt(response.data.data.aaveSmartWallets[0].assetBalances[i].principal, 10)
        this.tokensArray.push({"amount":(principal/10**decimals).toPrecision(9),"symbol":response.data.data.aaveSmartWallets[0].assetBalances[i].symbol,"hasInterest":"Yes"})
     
     }
   }
//console.log(this.tokensArray)
  }
this.showLoading=false;

}, (error) => {
if(error){
  this.showLoading=false;
  this.errormsg=true;

}
});

}, (error) => {
if(error){
  this.showLoading=false;
  this.errormsg=true;

}
});




    
 } else{
 this.msg="We could not locate that Big Bag #. It could be because it has not yet been minted."

 }

}else{
this.msg="Invalid Bag Request"

}
  },  
  async   findBag(){
if(isNaN(this.bagno)){
this.msg="Please enter a number"
}else if(this.bagno>0 && this.bagno<10001){
        let bagResult= await this.contract.BagIds(this.bagno);
      if(bagResult!=0){
       this.$router.replace({ path: `/v/${this.bagno}` })

      }else{
        this.msg="We could not locate that Big Bag#. It could be because it has not yet been minted."

      }

}else{
this.msg="Please enter a value between 1 to 10,000"

}

     },
      filterInput(e){
      e.target.value = e.target.value.replace(/[^0-9]+/g, '');
    },
     async getBagsCount(){
    this.bagsCount= await this.contract2.balanceOf(this.getActiveAccount.toLowerCase());
    if(this.bagsCount>0) {
  for(let i=0;i<this.bagsCount;i++){
     let tokenIds= await this.contract2.tokenOfOwnerByIndex(this.getActiveAccount.toLowerCase(),i);
      let bagIds= await this.contract.TokenIds(tokenIds);
      if(bagIds!=0){
       this.tokenIdsArray.push(tokenIds);
        this.bagIdsArray.push(bagIds);
      }
      
    }
    }
  
     },
  
 
  },
}
</script>
<style scoped>
.displayNFT p{
  color:#000;
    font-size:14px;

}
.displayNFT h5{
  color:#000;
}
.displayNFT a{
  color:#000;
  font-size:14px;
}
.app-link img{

        max-width: 300px;
  height: auto;
}
.mars-banner img{

        max-width: 100%;
  height: auto;
}
.small-text{
  padding-top: 20px;
}
.small-text p{

 font-size:12px;
}
.small-text a{
 font-size:12px;
}
.bg-set{
  padding:20px;
  background-color: #282828;
    border-radius: 25px;
}
</style>
