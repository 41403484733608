import Vue from "vue";
import Router from "vue-router";
import Home from "./pages/Home";
import Landing from "./pages/Landing";
import BagExplorer from "./pages/BagExplorer";



import Roadmap from "./pages/Roadmap";
import Team from "./pages/Team";
import FAQ from "./pages/FAQ";
import Guide from "./pages/Guide";

import ComingSoon from "./pages/ComingSoon";
import Mint from "./pages/Mint";
import ViewBag from "./pages/ViewBag";

Vue.use(Router);

export default new Router({
    // Make sure the server can handle the history mode
    // If not, set it to hash (or delete the mode)
    // More info here: https://router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations
    mode: "history",
    routes: [
        {
            path: "/",
            name: "home",
            component: Home
        },
        {
            path: "/landing",
            name: "landing",
            component: Landing
        },
       
        {
            path: "/roadmap",
            name: "roadmap",
            component: Roadmap
        },
        {
            path: "/team",
            name: "Team",
            component: Team
        },
        {
            path: "/faq",
            name: "FAQ",
            component: FAQ
        },
        {
            path: "/mint",
            name: "Mint",
            component: Mint
        },
        {
            path: "/guide",
            name: "Guide",
            component: Guide
        },
        {
            path: "/bagexplorer",
            name: "BagExplorer",
            component: BagExplorer
        },
        { path: '/v/:id', component: ViewBag, name:"ViewBag" },
        { path: '/viewbag/:id', component: ViewBag, name:"ViewBag" },
        {
            path: "/comingsoon",
            name: "ComingSoon",
            component: ComingSoon
        },
        
        
      
        { path: "*", component: ComingSoon }
       
      
    ],
    linkActiveClass: "active"
});