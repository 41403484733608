<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h1">Roadmap</h1>
    </div>



 <div class="bg-set">
<div class="container">



    <div class="rb-container">
      <ul class="rb">
        <li class="rb-item" ng-repeat="itembx">
          <div class="timestamp">
            Phase 1
          </div>
          <div class="item-title"><p> 1) Launch of Website, Discord and Twitter</p> 
<p> 2) Launch 10,000 Big Bags NFTs </p> 
<p> 3) Rigorous marketing</p> 
<p> 4) Move to Phase 2 upon successful completion of NFT sales</p> 
</div>
        </li>
        <li class="rb-item" ng-repeat="itembx">
          <div class="timestamp">
           Phase 2
          </div>
          <div class="item-title"><p>1) Release NFT Genuine Checker within 48 hours</p> 
<p>2) Release of Landing Page for each individual NFT</p> 
<p>3) Creation of Exclusive Big Bags Members only Club</p> 
</div>
        </li>

        <li class="rb-item" ng-repeat="itembx">
          <div class="timestamp">
            Phase 3
          </div>
          <div class="item-title"><p>1) Development and release of Metaverse storyline</p> 
<p>2) Merge with another project we are already working on. (Shhh...its a secret)</p> 
</div>
        </li>

      </ul>

  </div>
</div>
 </div>
<p> </p>
    <p class="mars-banner"><img src="mars4.png"></p>

 <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
  
    </div>

    
<footer class="page-footer font-small blue pt-4">

  <!-- Footer Links -->
  <div class="container-fluid text-center">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 mt-md-0">

        <!-- Content -->
        <h5 class="text-uppercase">BigBags.Club (2021)</h5>

      </div>
      <!-- Grid column -->

      <hr class="clearfix w-100 d-md-none pb-3">


    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->

  <!-- Copyright -->

</footer>

    <!-- <router-link v-if="isUserConnected" to="/set-value">
      <button type="button" class="btn btn-outline-primary btn-lg">Go set a new value!</button>
    </router-link> -->
  </div>
 
  
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Roadmap",
  computed: {
    ...mapGetters("accounts", ["getChainName", "isUserConnected"]),
  },
}
</script>
<style scoped>



.container {
 
  position: relative;

}




.rb-container {

  margin: auto;
  position: relative;
}

.rb-container ul.rb {
  padding: 0;
}

.rb-container ul.rb li {
  list-style: none;
  margin: auto;
  margin-left: 1em;
  min-height: 50px;
  border-left: 1px dashed #fff;
  padding: 0 0 30px 30px;
  position: relative;
}

.rb-container ul.rb li:last-child {
  border-left: 0;
}

.rb-container ul.rb li::before {
  position: absolute;
  left: -18px;
  top: -5px;
  content: " ";
  border: 8px solid rgba(255, 255, 255, 1);
  border-radius: 500%;
  background: #c51f26;;
  height: 30px;
  width: 30px;
  transition: all 500ms ease-in-out;
}

.rb-container ul.rb li:hover::before {
  border-color: #232931;
  transition: all 1000ms ease-in-out;
}

ul.rb li .timestamp {
  color: #c51f26;
  position: relative;
  width: 100px;
  font-size: 18px;
}

.item-title {
  color: #fff;
    font-size: 18px;

}



.app-link img{

        max-width: 300px;
  height: auto;
}
.mars-banner img{

        max-width: 100%;
  height: auto;
}
.bg-set{
  padding:20px;
  background-color: #282828;
    border-radius: 25px;
}
</style>
